// Customizable Area Start
import React, { useContext } from 'react'
import { Grid } from "@material-ui/core";
import {  Message, defaultProfile, pauseA, playButton, playedicon, playlistMoreTwoDots, reposticon } from '../../../../dashboard/src/assets';
import { useHistory } from "react-router-dom";
import { AudioContextApi } from '../../../../dashboard/src/AudioContext/AudioContext.web';



function AudioActivityNew({ data, time, type,song ,creativeById,PlaySong}: any) {
  const history = useHistory();

  const {handleAudioVideo, setHandleAudioVideo, handlePlayList} = useContext(AudioContextApi);

  const topPlayMusic = (item: any, index: any, songsList: any, trackName: any) => {
    if (handleAudioVideo === 1 || handleAudioVideo === 0) {
      handlePlayList(item, index, songsList, trackName)
    } else {
      setHandleAudioVideo(1)
    }
  
  }
  let selectedSong = localStorage.getItem("selectedSong")
  return (
    <>
      <Grid container spacing={1} className="activity-head-card">
        <Grid item style={{ width: '100%' }} >
          <Grid style={{ color: 'white', borderColor: 'white' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
              <img src={creativeById?.attributes?.avatar || defaultProfile} style={{
                  width: "38px",
                  height: "38px",
                  borderRadius: "50%"
                }} />
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                  <div className='activity-user_name'>
                    {creativeById?.attributes?.user_name|| creativeById?.attributes?.full_name}

                    </div>
                  <div className='activity-duration'>{time} {time != "Just Now" && "ago"}</div>
                </div>
              </div>
              <div >
                <img src={type} style={{
                  height: "24px",
                  width: "24px"
                }} />
              </div>
            </div>
            <div className='caption-reposted'>{data?.caption || data?.repost_caption}</div>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '100px', height: '100px', position: 'relative' }}>
                <div style={{ position: 'absolute' }}>
                  <img src={data?.artwork || data?.art_work}
                    style={{
                      height: "85px",
                      width: "86.5px",
                      borderRadius: "12px",
                      backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.21), #000)",
                      cursor:"pointer",
                      objectFit:"cover"
                    }}
                    onClick={() => {
                      topPlayMusic(song, song.id, {}, song.attributes.track_upload_type)}
                    }
                  />
                </div>
                <div style={{ position: 'absolute', top: '30%', left: '30%' }}>
                  <img
                     src={selectedSong && selectedSong.split('"')[1] === song?.id && handleAudioVideo === 2 ? pauseA : playButton}
                    style={{ height: '25px', cursor: 'pointer' }}
                    onClick={() => {
                      topPlayMusic(song, song.id, {}, song.attributes.track_upload_type)}
                    }
                    />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {data?.title || data?.track_title}
                </div>
                <div className="activity-artistName">
                  { data?.artist_name ||data?.track_creative_name }
                </div>
                <div className='activity-timeDuration'>
                {data?.time_duration||data?.track_length}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div className='activity-three-head'>
                <div className="activity-three-data"><img src={playedicon} /> {data?.track_play_count || data?.play_count||"0"}</div>
                <div className="activity-three-data"> <img src={Message} style={{ height: '15px',marginRight:"4px" }} /> {data?.track_comments_count || "0"}</div>
                <div className="activity-three-data"> <img src={reposticon} /> {data?.repost_count || "0"}</div>
              </div>
              <div>
                <img src={playlistMoreTwoDots} 
                     onClick={() => {
                      history.push({
                        pathname: `/home/expand/song/songId=${song.id}`,
                        state: { data: song }
                      })
                    }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default AudioActivityNew
// Customizable Area End