// Customizable Area Start
import React, { useContext } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CaretRightOutlined } from "@ant-design/icons";
import { Slider } from "antd";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import {
  defaultProfile,
  musicDollar,
  musicMore,
  musicNext,
  musicPlaylist,
  musicPrev,
} from "../../dashboard/src/assets";
import { pauseA, repeatA, volumeA, repeatBlue } from "./assets";
import { useHistory } from "react-router-dom";
import { AudioContextApi } from "../../dashboard/src/AudioContext/AudioContext.web";

function CustomAudioPlayerCoverImage(props: any) {
  const {
    checkType,
    playlistData,
    attributes,
    artist_name,
    title,
    loopSys,
    AudioLoop,
    toPrevTrack,
    playList,
    loader,
    finishCheck,
    restart,
    playBt,
    pause,
    play,
    StartTimeCalc,
    nextOrder,
    seekBar,
    seekValue,
    endingTime,
    endTime,
    token,
    downloadType,
    sound,
    handleAddToPlaylist,
    soundOn,
    soundOff,
    volumeChange,
  } = props;
  const history = useHistory();
  //@ts-ignore
  const { queueSongs } = useContext(AudioContextApi);

  const playToolTip = () => {
    if (playBt) {
      return (
        <Tooltip placement="top" title="Pause">
          <img
            src={pauseA}
            id="music-icon"
            onClick={pause}
            className="music-play-icon"
            alt=""
          />
        </Tooltip>
      );
    } else {
      return (
        // play Buttion
        <Tooltip placement="top" title="Play">
          {
            //@ts-ignore 
            <CaretRightOutlined
              id="coverImage_play_test_id"
              onClick={() => play(playlistData?.id)}
              style={{
                fontSize: "25px ",
                background: "#3959f9 ",
                borderRadius: "50%",
                padding: "7px 6px 7px 8px",
                color: "white",
              }}
            />
          }
        </Tooltip>
      );
    }
  };

  const finishCheckternary = () => {
    if (finishCheck()) {
      return (
        // @ts-ignore
        <CaretRightOutlined
          onClick={restart}
          style={{
            fontSize: "25px ",
            background: "#3959f9 ",
            borderRadius: "50%",
            padding: "7px 6px 7px 8px",
            color: "white",
          }}
        />
      );
    } else {
      return playToolTip();
    }
  };

  const redirectExpandMore = () => {
    if (playlistData?.attributes?.track_id != ("" || null)) {
      history.push(
        `/home/expand/song/songId=${playlistData?.attributes?.track_id}`
      );
    } else {
      history.push({
        pathname: `/home/expand/song/songId=${playlistData.id}`,
        // state: { data: playlistData },
      });
    }
  };
  return (
    <div className="audio-player">
      <div className="song-details">
        <img
          src={
            checkType === "Playlists"
              ? playlistData?.attributes?.cover_image
              : attributes?.artwork || attributes?.art_work ||
              attributes?.avatar ||
              attributes?.track_artwork ||
              defaultProfile
          }
          alt=""
        />
        <div className="label">
          <h3 className="song-title-custom">
            {checkType === "Playlists"
              ? playlistData?.attributes?.name
              : title ||
              attributes?.track_name ||
              attributes.track_title ||
              "Unknown"}
          </h3>
          <p className="song-title-custom">{artist_name || "Unknown"}</p>
        </div>
      </div>

      <div className="song-control">
        <div className="play-control">
          <Tooltip
            placement="top"
            title="Currently playing songs will be repeated"
          >
            {loopSys ? (
              <img
                id="music-icon"
                src={repeatBlue}
                onClick={() => AudioLoop(false)}
              />
            ) : (
              <img
                test-id="audio_loop_cover_image_test_id"
                src={repeatA}
                id="music-icon"
                onClick={() => AudioLoop(true)}
              />
            )}
          </Tooltip>
          <Tooltip placement="top" title="Previous">
            <input
              type="image"
              src={musicPrev}
              className="music-icon-size"
              onClick={toPrevTrack}
              style={{
                width: "38px",
                height: "37px",
                cursor: queueSongs.length > 0 ? "not-allowed" : "pointer",
              }}
              alt=""
              disabled={!Array.isArray(playList) || queueSongs.length > 0}
            />
          </Tooltip>
          {loader ? <CircularProgress /> : finishCheckternary()}
          <Tooltip placement="top" title="Next">
            <input
              type="image"
              src={musicNext}
              className="music-icon-size"
              onClick={nextOrder}
              alt=""
              disabled={!Array.isArray(playList)}
            />
          </Tooltip>
          {/* <Tooltip placement="top" title="Songs in a queue will be shuffled">
                      <img src={isShuffle ? shuffle_18 : shuffleA}
                        alt="" onClick={() => setIsShuffle(!isShuffle)} className="music-icon-size" />
                    </Tooltip> */}

          <Tooltip placement="top" title="Redirect to Songs Details">
            <img
              test-id="redirectExpand_cover_image_test_id"
              //  src={isShuffle ? shuffle_18 : shuffleA}
              src={musicMore}
              alt=""
              onClick={() => redirectExpandMore()}
              className="music-icon-size"
            />
          </Tooltip>
        </div>

        <div className="progress-bar">
          <p>
            {StartTimeCalc().minutes}:{StartTimeCalc().seconds}
          </p>
          {/* progresss bar..................................... */}
          {/*@ts-ignore*/}
          <Slider
            className="slider-bar"
            onChange={seekBar}
            value={seekValue}
            trackStyle={{ backgroundColor: "blue", height: "8px" }}
            tooltip={{
              open: false,
            }}
          />

          {endingTime ? (
            <p style={{ color: "#666669" }}>
              {endTime().minutes}:{endTime().seconds}
            </p>
          ) : (
            <p>0:0</p>
          )}
        </div>
      </div>
      <div
        className="song-volume"
        style={{
          gap: token ? "20px" : "45px",
        }}
      >
        {downloadType}
        <div className="menuIcon cursor_pointer ">
          <img
            src={musicPlaylist}
            onClick={handleAddToPlaylist}
            className="music-icon-size"
            alt=""
          />
        </div>
        <div className="menuIcon cursor_pointer music-icon-size">
          <img src={musicDollar} className="music-icon-size cursor-not-allowed" alt="" 
          // onClick={() => {
          //   history.push('/deal')
          //   localStorage.removeItem("selectedSingleVideo")
          // }} 
          />
        </div>

        <div
          className="volune"
          style={
            {
              // marginRight: token ? "0%" : "20%",
              //  gap: token ? "20px" : "45px"
            }
          }
        >
          {sound ? (
            <>
              <VolumeOffIcon
                id="music-icon"
                onClick={soundOn}
                style={{ color: "white" }}
              />
              {/*@ts-ignore*/}
              <Slider
                className="seek-bar"
                trackStyle={{ backgroundColor: "red" }}
                onChange={volumeChange}
                min={0}
                max={1}
                step={0.01}
                value={0}
                // disabled={true}
                tooltip={{
                  open: false,
                }}
              />
            </>
          ) : (
            <>
              <img
                src={volumeA}
                id="music-icon-volumn"
                onClick={soundOff}
                alt=""
              />
              {/*@ts-ignore*/}
              <Slider
                className="seek-bar"
                trackStyle={{ backgroundColor: "red" }}
                onChange={volumeChange}
                min={0}
                max={1}
                step={0.01}
                defaultValue={1}
                tooltip={{
                  open: false,
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomAudioPlayerCoverImage;
// Customizable Area End
